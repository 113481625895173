import React, { useState, useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { VideoProvider } from "./components/context/VideoContext";
import { LoadingProvider, useLoading } from "./components/context/LoadingProvider";
import "./App.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import LoadingScreen from "./components/LoadingScreen";
import ProtectedRoute from "./components/context/ProtectedRoute";
import DynamicBackgroundStripes from "./components/DynamicBackgroundStripes";

import { Amplify } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { getUserAttributes } from "./APICalls/getUserAttributes";
import awsExports from "./aws-exports";

Amplify.configure(awsExports);
library.add(faBars);

// Lazy load pages for code-splitting
const HomePage = lazy(() => import("./pages/HomePage"));
const ExplorePage = lazy(() => import("./pages/ExplorePage"));
const LandingPreviewPage = lazy(() => import("./pages/LandingPreviewPage"));
const SubscribePage = lazy(() => import("./pages/SubscribePage"));
const FAQPage = lazy(() => import("./pages/FAQPage"));
const PrivacyPage = lazy(() => import("./pages/PrivacyPage"));
const TermsPage = lazy(() => import("./pages/TermsPage"));
const NotFound = lazy(() => import("./pages/NotFound"));
const HelpPage = lazy(() => import("./pages/HelpPage"));
const ProfilePage = lazy(() => import("./pages/ProfilePage"));
const WatchPage = lazy(() => import("./pages/WatchPage"));
const GenresPage = lazy(() => import("./pages/GenresPage"));
const ArtistsPage = lazy(() => import("./pages/ArtistsPage"));
const PPVPage = lazy(() => import("./pages/PPVPage"));
const PPVPreviewPage = lazy(() => import("./pages/PPVPreviewPage"));
const VideosPage = lazy(() => import("./pages/VideosPage"));
const VideoPreviewPage = lazy(() => import("./pages/VideoPreviewPage"));
const VideoPlayerSetup = lazy(() => import("./pages/VideoPlayerSetup"));
const DesignTestPage = lazy(() => import("./pages/DesignTestPage"));

function App() {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [userAttributes, setUserAttributes] = useState(null);
  const { user, authStatus } = useAuthenticator((context) => [context.user, context.authStatus]);

  // Fetch user attributes only after the user is authenticated
  useEffect(() => {
    const fetchAttributes = async () => {
      if (authStatus === "authenticated" && user) {
        const attributes = await getUserAttributes();
        setUserAttributes(attributes);
      }
    };
    fetchAttributes();
  }, [authStatus, user]);

  return (
    <LoadingProvider>
      <HelmetProvider>
        <Router>
          <VideoProvider>
            <AppContent
              showLoginModal={showLoginModal}
              setShowLoginModal={setShowLoginModal}
              userAttributes={userAttributes}
            />
          </VideoProvider>
        </Router>
      </HelmetProvider>
    </LoadingProvider>
  );
}

const AppContent = React.memo(({ showLoginModal, setShowLoginModal, userAttributes }) => {
  const { loading } = useLoading();

  return (
    <div className="master-container w-full min-w-[320px] scrollbar-webkit">
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <DynamicBackgroundStripes />
          <Helmet>
            <title>Vercee - Live Music Reimagined</title>
            <meta
              name="description"
              content="Join Vercee to experience live-streamed concerts and exclusive content from top artists."
            />
          </Helmet>
          <Navbar showLoginModal={showLoginModal} setShowLoginModal={setShowLoginModal} />
          <main className="main-content-container flex flex-col items-center">
            <Suspense fallback={<LoadingScreen />}>
              <Routes>
                {/* Public routes */}
                <Route path="/" element={<HomePage setShowLoginModal={setShowLoginModal} />} />
                <Route path="/preview/:previewId" element={<LandingPreviewPage />} />
                <Route path="/faq" element={<FAQPage />} />
                <Route path="/privacy" element={<PrivacyPage />} />
                <Route path="/terms" element={<TermsPage />} />
                <Route path="/help" element={<HelpPage />} />
                <Route path="/video-player-setup/:videoId" element={<VideoPlayerSetup />} />
                <Route path="/videos/:videoId" element={<VideoPreviewPage />} />
                <Route path="/ppv/:videoId" element={<PPVPreviewPage />} />
                <Route path="/design" element={<DesignTestPage />} />

                {/* Protected routes */}
                <Route
                  path="/explore"
                  element={
                    <ProtectedRoute>
                      <ExplorePage userAttributes={userAttributes} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <ProtectedRoute>
                      <ProfilePage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/subscribe"
                  element={
                    <ProtectedRoute>
                      <SubscribePage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/videos"
                  element={
                    <ProtectedRoute>
                      <VideosPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/artists"
                  element={
                    <ProtectedRoute>
                      <ArtistsPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/genres"
                  element={
                    <ProtectedRoute>
                      <GenresPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/ppv"
                  element={
                    <ProtectedRoute>
                      <PPVPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/watch"
                  element={
                    <ProtectedRoute>
                      <WatchPage />
                    </ProtectedRoute>
                  }
                />

                {/* Catch-all route */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Suspense>
            <Footer />
          </main>
        </>
      )}
    </div>
  );
});

export default App;
