import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const DropdownMenuModal = ({ user, handleSignOut }) => {
  const dropdownRef = useRef(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const closeDropdown = () => {
    setIsDropdownVisible(false);
  };

  // Close dropdown if the user clicks outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !event.target.closest(".dropdown-icon")
      ) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav className="relative dropdown-container flex flex-row justify-end z-50">
      <div
        className="dropdown-icon btn btn-ghost normal-case text-xl text-white hover:cursor-pointer"
        onClick={toggleDropdown}
      >
        {isDropdownVisible ? (
          <FontAwesomeIcon icon={faXmark} className="lg:text-[30px]" />
        ) : (
          <FontAwesomeIcon icon={faBars} className="lg:text-[30px]" />
        )}
      </div>
      {isDropdownVisible && (
        <div
          className="p-px bg-gradient rounded-3xl absolute top-8 -right-6 w-screen md:right-0 md:w-[320px] min-w-[320px] mx-auto"
          ref={dropdownRef}
        >
          <div className="bg-radial-charcoal-gradient p-6 rounded-[calc(1.5rem-1px)]">
            <div className="dropdown-container">
              <div className="profile-section flex flex-row justify-center w-full pb-2 mx-auto border-t-0 border-x-0 border-purple border-4">
                <Link to="/profile" onClick={closeDropdown} className="text-xl underline flex flex-row">
                  <div className="flex aspect-square content-center justify-center align-middle mx-1 bg-radial-charcoal-gradient border-white border rounded-full w-8">
                    <FontAwesomeIcon icon={faUser} className="self-center" />
                  </div>
                  <div className="text-center content-start">{user.signInDetails.loginId}</div>
                </Link>
              </div>
              <div className="dropdown-extras-container h-full">
                <ul className="flex flex-col items-end pt-2 text-xl">
                  <li>
                    <Link
                      to="/explore"
                      onClick={closeDropdown}
                      className="btn btn-ghost underline w-full normal-case text-left px-4 py-2"
                    >
                      Explore
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/subscribe"
                      onClick={closeDropdown}
                      className="btn btn-ghost underline w-full normal-case text-left px-4 py-2"
                    >
                      Subscriptions
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/ppv"
                      onClick={closeDropdown}
                      className="btn btn-ghost underline w-full normal-case text-left px-4 py-2"
                    >
                      PPV's
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/videos"
                      onClick={closeDropdown}
                      className="btn btn-ghost underline w-full normal-case text-left px-4 py-2"
                    >
                      VOD's
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to="/help"
                      onClick={closeDropdown}
                      className="underline btn btn-ghost w-full normal-case text-left px-4 py-2"
                    >
                      Help Center
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      className="underline btn btn-ghost w-full normal-case text-left px-4 py-2"
                      onClick={handleSignOut}
                    >
                      Sign Out
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default DropdownMenuModal;
