import React, { useEffect, useRef } from "react";

const DynamicBackgroundStripes = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Function to resize the canvas excluding scrollbar width
    const setCanvasSize = () => {
      const width = document.documentElement.clientWidth; // Excludes scrollbar
      const height = document.documentElement.clientHeight; // Excludes scrollbar
      canvas.width = width;
      canvas.height = height;
    };

    setCanvasSize(); // Initial sizing
    window.addEventListener("resize", setCanvasSize);

    // Animation variables
    const lineCount = 20;
    const lineSpacing = 50;
    const waveSpeed = 0.005;
    const colors = [
      "rgba(28, 6, 55, 0.8)",
      "rgba(117, 25, 229, 0.6)",
      "rgba(157, 50, 183, 0.5)",
      "rgba(195, 72, 140, 0.4)",
      "rgba(230, 94, 100, 0.3)"
    ];
    let offset = 0;

    const drawLines = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.lineWidth = 2;

      for (let i = 0; i < lineCount; i++) {
        ctx.beginPath();
        ctx.strokeStyle = colors[i % colors.length];
        const y = canvas.height / 2 + i * lineSpacing - (lineCount / 2) * lineSpacing;

        for (let x = 0; x < canvas.width; x++) {
          const waveHeight = 30;
          const frequency = 0.01;
          const newY = y + Math.sin(x * frequency + offset + i * 0.001) * waveHeight;
          ctx.lineTo(x, newY);
        }
        ctx.stroke();
      }
      offset += waveSpeed;
    };

    const animate = () => {
      drawLines();
      requestAnimationFrame(animate);
    };

    animate();

    return () => {
      window.removeEventListener("resize", setCanvasSize);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: -1,
        background: "linear-gradient(135deg, #010001, #000000)"
      }}
    />
  );
};

export default DynamicBackgroundStripes;
