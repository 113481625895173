import React, { useEffect, useRef, useCallback } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { signOutUser } from "../APICalls/auth";
import { Link, useNavigate } from "react-router-dom";
import LoginModal from "./Modals/LoginModal";
import SearchBar from "./SearchBar";
import DropdownMenuModal from "./Modals/DropdownMenuModal";

const Navbar = ({ showLoginModal, setShowLoginModal }) => {
  const modalRef = useRef(null);
  const { user } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();

  //handle opening the login modal
  const handleSignInClick = () => {
    setShowLoginModal(true);
  };

  const handleSignOut = async () => {
    await signOutUser();
    // Redirect to home or handle UI updates after sign-out
  };

  // Automatically close the login modal on successful login
  useEffect(() => {
    if (user) {
      setShowLoginModal(false);
    }
  }, [user, navigate, setShowLoginModal]);

  // Handle closing the login modal
  const handleCloseModal = useCallback(() => {
    setShowLoginModal(false);
  }, [setShowLoginModal]);

  // Close modal if the user clicks outside the modal
  useEffect(() => {
    const handleClickOutsideDropdown = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleCloseModal();
      }
    };
    document.addEventListener("mousedown", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    };
  }, [handleCloseModal]);

  return (
    <header className="navbar-container w-full flex flex-row p-4 pb-2 lg:p-6 lg:pb-2 2xl:p-8 2xl:pb-4 z-50 bg-gradient-to-b from-black to-transparent">
      {/* Left Section: Logo with Gradient */}
      <div className="nav-logo-container flex flex-row w-[25%]">
        <Link to="/explore" className="flex flex-row text-white">
          <img
            src="/verceelogo512-white.png"
            alt="vercee-logo"
            className="w-[2rem] lg:w-[2.5rem] xl:w-[3rem] 2xl:w-[3.5rem] min-w-[42px]"
          />
          <h1 className="-ml-2 pl-4 text-sm md:text-lg lg:text-xl xl:text-2xl rounded-r-3xl hover:underline hidden md:flex items-center">
            Vercee
          </h1>
        </Link>
        <div className="flex items-center">
          {/* If the user isnt logged in, show the signup button */}
          {/* {!user && (
            <div className="p-px bg-white rounded-3xl mx-2 min-w-[55px] lg:min-w-[65px] 2xl:ml-6 drop-shadow-md">
              <div
                onClick={handleSignInClick}
                className="bg-none text-sm lg:text-lg text-center rounded-[calc(1.5rem-1px)] cursor-pointer w-full hover:bg-red-50"
              >
                <p className="text-transparent bg-radial-gradient bg-clip-text">Sign In</p>
              </div>
            </div>
          )} */}
        </div>
      </div>
      {/* Center Section: Transparent */}
      <div className="nav-center-container flex items-center content-center w-full px-2">
        {/* SearchBar is shown when the user is logged in */}
        {user && (
          <div className="searchbar-container w-full">
            <SearchBar />
          </div>
        )}
      </div>
      {/* Right Section: Profile Dropdown or Sign In with Gradient */}
      <div className="nav-right-container flex flex-grow items-center justify-end pr-2 min-w-[55px] w-[25%]">
        {/* If the user is logged in, show the dropdown menu */}
        {user ? <DropdownMenuModal user={user} handleSignOut={handleSignOut} /> : <></>}
        {/* for development use, shows the media screen size in navbar to help with UI styling  */}
        {/* <div className="dev-square-container absolute top-8 right-20">
          <p className="development-square-for-sizing text-center flex flex-row justify-center w-6 h-6 text-xs bg-red-800 sm:hidden md:hidden lg:hidden xl:hidden 2xl:hidden 3xl:hidden wide:hidden">
            xsm
          </p>
          <p className="development-square-for-sizing text-center hidden flex-row justify-center w-6 h-6 text-xs bg-orange-800 sm:flex md:hidden lg:hidden xl:hidden 2xl:hidden 3xl:hidden wide:hidden">
            sm
          </p>
          <p className="development-square-for-sizing text-center hidden flex-row justify-center w-6 h-6 text-xs bg-yellow-800 sm:hidden md:flex lg:hidden xl:hidden 2xl:hidden 3xl:hidden wide:hidden">
            md
          </p>
          <p className="development-square-for-sizing text-center hidden flex-row justify-center w-6 h-6 text-xs bg-green-800 sm:hidden md:hidden lg:flex xl:hidden 2xl:hidden 3xl:hidden wide:hidden">
            lg
          </p>
          <p className="development-square-for-sizing text-center hidden flex-row justify-center w-6 h-6 text-xs bg-blue-800 sm:hidden md:hidden lg:hidden xl:flex 2xl:hidden 3xl:hidden wide:hidden">
            xl
          </p>
          <p className="development-square-for-sizing text-center hidden flex-row justify-center w-6 h-6 text-xs bg-indigo-800 sm:hidden md:hidden lg:hidden xl:hidden 2xl:flex 3xl:hidden wide:hidden">
            2xl
          </p>
          <p className="development-square-for-sizing text-center hidden flex-row justify-center w-6 h-6 text-xs bg-violet-800 sm:hidden md:hidden lg:hidden xl:hidden 2xl:hidden 3xl:flex wide:hidden">
            3xl
          </p>
          <p className="development-square-for-sizing text-center hidden flex-row justify-center w-6 h-6 text-xs bg-black sm:hidden md:hidden lg:hidden xl:hidden 2xl:hidden 3xl:hidden wide:flex small-wide:hidden">
            wide
          </p>
          <p className="development-square-for-sizing text-center hidden flex-row justify-center w-6 h-6 text-xs bg-gray-500 sm:hidden md:hidden lg:hidden xl:hidden 2xl:hidden 3xl:hidden wide:hidden small-wide:flex">
            small-wide
          </p>
        </div> */}
      </div>

      {/* Pass modalRef to the LoginModal */}
      {showLoginModal && <LoginModal handleCloseModal={handleCloseModal} modalRef={modalRef} />}
    </header>
  );
};

export default Navbar;
