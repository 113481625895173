import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useLoading } from "./LoadingProvider";

function ProtectedRoute({ children }) {
  const { authStatus } = useAuthenticator((context) => [context.user, context.authStatus]);
  const { setLoading } = useLoading();

  useEffect(() => {
    setLoading(true);
    if (authStatus !== "loading") {
      setLoading(false);
    }
  }, [authStatus, setLoading]);

  if (authStatus === "loading") {
    return null; // Use global loading screen
  }

  if (authStatus === "unauthenticated") {
    return <Navigate to="/" replace />;
  }

  return children;
}

export default ProtectedRoute;
